$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
{
  padding-left: 16px;
  padding-right: 16px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #333333;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 16px 28px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 1200px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.container {
  .navbar-header, .navbar-collapse {
      margin-left: -40px; margin-right: -40px;
    }

  @media screen and  (max-width: 991px) {
    padding: 0 20px;
  }

  @media screen and  (min-width: 992px) {
    padding: 0 8%;
  }

  @media screen and  (min-width: 1200px) {
    padding: 0 6%;
  }

  @media screen and  (min-width: 1600px) {
    padding: 0 2%;
  }
}

$font-family_oswald: Oswald;
$color_white: #fff;
$color_footer-blue: #0e4359;
$color_dark-blue: #1a3866;
$color_light-blue: #008ac9;
$color_input-border: #58a6cd;
$color_sky-blue: #c7f1ff;
$color_green: #7faf2b;
$color_dark-green: #7fae2b;
$color_standard-green: #48a200;
$color_brown: #ba8251;
$color_red: #eb1c24;
$color_red_hover: #da131b;
$color_logo-red: #ec1f24;
$color_box: #f1f5f8;
$color_light-box: #f1f5f7;
$color_grey-light: #6c8c9c;
$color_dark-grey: #4b5357;
$color_very-dark-grey: #333;
$color_almost-black: #000102;
$color_table-border: #b9c6cf;
$color_table-bg: #e9eef1;
$color_collapse-border: #d4dfe8;

$w479max: "max-width: 479px";
$w480: "min-width: 480px";
$w767max: "max-width: 767px";
$w768: "min-width: 768px";
$w991max: "max-width: 991px";
$w992: "min-width: 992px";
$w1199max: "max-width: 1199px";
$w1200: "min-width: 1200px";

$font-oswald: Oswald;
$color-white: #fff;


h2 {
  margin: 0px;
  padding: 64px 0px 30px 0px;
  margin: 0px;
  font-family: $font-oswald;
  font-weight: 500;
  color: #203868;

  @media screen and (max-width: 767px) {
    padding: 44px 0px 20px 0px;
    font-size: 36px; 
  }

  @media screen and (min-width: 768px) { 
    padding: 64px 0px 30px 0px;
    font-size: 36px; 
  }
}


// NAVIGACE + HEADER
.navbar-nav {
  li {
    font-family: $font-oswald;
    font-weight: 500;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1199px) { 
    padding-right: 20px;
    font-size: 15px;
  }

  @media screen and (min-width: 1200px) { 
    padding-right: 20px;
    font-size: 18px;
  }
}

.navbar-header { margin: 0px !important; max-width: 100%; margin-right: 0px; border-bottom: 0px solid #e74c3c; }
.navbar-default .navbar-nav > li > a:hover { text-decoration: none; }
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus { background-color: transparent; }
.navbar-default .navbar-toggle .icon-bar { background-color:  #203868; }
.navbar-default .navbar-toggle { margin: 0px; border-color:  #203868 !important; }
.navbar-inverse .navbar-collapse {  border-color: transparent;  }
.navbar-default .navbar-collapse { background-color: #fff; }

.navbar-default .navbar-collapse { 
  @media screen and (max-width: 768px) { border-top: none; }
}

.navbar-default .navbar-nav > li > a {
  color: #192B4F; 

  @media screen and (min-width: 992px) { padding-left: 36px; padding-right: 0px; }

  @media screen and (min-width: 992px) { padding-left: 48px; padding-right: 0px; }
}

.navbar { 
  height: 66px;
  border-bottom: none;
  background-color: #fff; 
}

.navbar-right { padding-top: 8px; }
.navbar-default .navbar-collapse { width: 100%; padding-left: 60px; text-align: left; }
.navbar-default .navbar-toggle { margin-top: 16px; }

.navbar-brand {
  padding-top: 0px;
  padding-left: 0px;

  img {
    z-index: 5;

    @media screen and (max-width: 767px) { height: 66px; }
    
    @media screen and (min-width: 768px) { 
      height: 96px; 
      margin-left: -8px;
    }

    @media screen and (min-width: 992px) {
      margin-left: -62px;
      height: 140px; 
    }

    @media screen and (min-width: 1200px) {
      margin-left: -64px;
      height: 160px; 
    }

    @media screen and (min-width: 1400px) {
      margin-left: -96px;
      height: 160px; 
    }

    @media screen and (min-width: 1920px) {
      margin-left: -128px;
      height: 160px; 
    }
  }
}

.introduce {
  .title {
    font-family: $font-oswald;
    font-weight: 400;
    color: $color-white;
    
    @media screen and (max-width: 479px) {
      margin: 120px 0px 30px 18px;
      font-size: 36px;
      line-height: 48px;
    }

    @media screen and (min-width: 480px) {
      margin: 120px 0px 40px 18px;
      font-size: 36px;
      line-height: 48px;
    }

    @media screen and (min-width: 768px) {
      margin: 160px 0px 40px 20px;
      font-size: 44px; 
      line-height: 60px;
    }

    @media screen and (min-width: 992px) {
      margin: 164px 0px 0px 0px;
      font-size: 44px; 
      line-height: 78px;
    }

    @media screen and (min-width: 1200px) {
      margin: 164px 0px 0px 0px;
      font-size: 54px; 
      line-height: 78px;
    }

    @media screen and (min-width: 1600px) {
      margin: 164px 0px 0px 0px;
      font-size: 54px; 
      line-height: 78px;
    }
  }

  .page-title  { background-color: #203868; }

  .page-title h1 { 
    padding: 128px 0px 48px 0px; 
    font-family: Oswald; 
    font-weight: 400; 
    font-size: 36px; 
    text-align: left; 
    color: #fff; 
  }
}

.sub-title {
  max-width: 700px;
  font-family: $font-oswald;
  font-weight: 300;
  color: $color-white;

  @media screen and (max-width: 1199px) {
    margin: 10px 52px 10px 0px;
    font-size: 18px;
    line-height: 30px;
  }

  @media screen and (min-width: 1200px) {
    margin: 10px 52px 10px 0px;
    font-size: 28px;
    line-height: 42px;
  }

  @media screen and (min-width: 992px) { margin: 10px 52px 10px 0px; }

  @media screen and (min-width: 1200px) { margin: 10px 52px 10px 0px; }
}

.begin_wrapper {
  background-color: rgba(20, 130, 180, 0.1);
  padding-bottom: 64px;

  .begin_wrapper__introduce-text {
    display: inline-block; 
    text-align: justify;
    font-size: 15px;  
    color: #203868;

    @media screen and (min-width: 479px) { 
      padding-left: 30px; 
      padding-right: 30px;
    }

    @media screen and (min-width: 480px) { 
      padding-left: 36px; 
      padding-right: 36px; 
    }
    
    @media screen and (min-width: 768px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }

    @media screen and (min-width: 992px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }

    @media screen and (min-width: 1200px) {
      padding-left: 10%; 
      padding-right: 10%;  
    }
  }

  h2 { text-align: center; }
}


// FOOTER: Navigace + odkazy na sociální sítě
.footer-navigation-wrapper { background-color: #192B4F; }


.footer-navigation {
  @media screen and (max-width: 991px) { 
    padding-top: 24px;
    padding-bottom: 48px;
  }

  @media screen and (min-width: 992px) { 
    padding-top: 48px;
    padding-bottom: 48px;
  }

  h6 {
    display: block;
    padding: 0px;
    font-family: $font-family_oswald;
    font-weight: 400;
    font-size: 22px;
    color: $color_white;

    @media screen and (max-width: 767px) { 
      margin: 0px; 
      padding-bottom: 0px;
    }

    @media screen and (min-width: 992px) {
      padding-bottom: 0px;
      margin: 0px;
    }
  }

  ul {
    padding-left: 0px;
    list-style-type: none;

    @media screen and (max-width: 767px) { padding-bottom: 38px; }

    li {
      padding-top: 4px;
      color: $color_white;

      a {
        font-family: Arial;
        font-size: 15px;
        text-decoration: underline;
        color: $color_white;
      }

      :hover { color: $color_sky-blue; }
    }
  }

  .contact-wrapper {
    @media screen and (max-width: 991px) {
      padding: 20px 40px 20px 40px;
    }

    @media screen and (min-width: 992px) {
      padding: 48px 0px 48px 0px;
    }

    .link {
      display: block;
      padding-bottom: 6px;
      font-size: 15px;
      text-decoration: underline;
      color: #fff;
    }

    .links {
      @media screen and (min-width: 992px) {
        margin-top: 10px;
      }
    }

    h6 {
      @media screen and (max-width: 991px) { margin: 0px; }
    }

    div {
      margin-bottom: 10px;

      @media screen and (max-width: 991px) { 
        display: blocks; 
        float: none;
      }

      @media screen and (min-width: 1200px) { 
        display: inline-block; 
        float: left;
        padding-left: 28px;
      }

      @media screen and (min-width: 1200px) { padding-left: 0px; }
      
      h6 {
        display: block;
        margin: 0px;
      }

      div {
        span {
          display: inline-block;
          padding: 0px;
          font-family: $font-family_oswald;
          font-weight: 400;

          @media screen and (max-width: 479px) { width: 160px; font-size: 15px; }
          @media screen and (min-width: 480px) { width: 200px; font-size: 18px; }
          @media screen and (min-width: 768px) { width: 240px; }
        }

        a { color: $color_white; }

        // ikonka telefonu
        .phone:before {
          content: url('https://www.dtest.cz/data/images/91446.png');

          @media screen and (max-width: 479px) {  padding-right: 10px; }
          @media screen and (min-width: 480px) {  padding-right: 30px; }
        }

        // ikonka e-mailu
        .mail:before {
          content: url('https://www.dtest.cz/data/images/91445.png');
          
          @media screen and (max-width: 479px) {  padding-right: 10px; }
          @media screen and (min-width: 480px) {  padding-right: 30px; }
        }
      }

      // ikonky soc. sítí
      img {
        height: 42px;
        padding-bottom: 5px;
        padding-left: 16px;
      }
    }
  }
}


// FOOTER: Copyright
.footer-copyright {
  padding: 30px 0px 30px 0px;
  font-size: 12px;
  color: #356073;

  div:first-of-type { text-align: left; }

  div:last-of-type {
    @media screen and (max-width: 767px) {
      padding-top: 20px;
      text-align: left;
    }

    @media screen and (min-width: 768px) { text-align: right; }
  }
}

.column {
  @media screen and (min-width: 992px) { padding-top: 48px; }
}

.partners_wrapper {
  padding-bottom: 64px;
  background-color:: #fff;

  h2 { text-align: center; }
}

.heading {
  text-align: center;

  h2 {
    display: inline-block;
  }
}

#smaller-border {
  height: 4px;
  width: 54%;
  margin: -0.75rem auto 0 auto;
  background-color: #203868;
  margin-top: 15px;
}

#footer-border {
  display: block;
  height: 4px;
  width: 30px;
  margin: 6px 0px 0px 0px;
  padding-bottom: 0px;
  background-color: #fff;
}

hr {
  margin-top: 6px;
  margin-bottom: 6px;
}

.attribut-info {
  @media screen and (max-width: 767px) { display: block; }
  @media screen and (min-width: 768px) { display: inline-block; }
}

.form-sent {
  padding-top: 16px;
  font-family: Arial;
  font-weight: bold;
  font-size: 18px;
}

.download-text {
  @media screen and (max-width: 767px) { padding: 0px 20px 0px 20px; }
  @media screen and (min-width: 768px) { padding: 0px }
}

.download-link {
  @media screen and (max-width: 767px) { padding: 0px 20px 30px 20px; }
  @media screen and (min-width: 768px) { padding: 0px }
}


/* -----------------
 * NEFÉROVÉ SEZNAMKY
 * ----------------- */

.news_wrapper {
  background-color: #c7d3ea;

  .step {
    position: relative;
    margin-top: 60px;
    padding: 20px;
    background: #fff;

    h3 {
      padding-bottom: 16px;
      font-family: Oswald;
      font-weight: 500;
      color: #203868;
    }

    span {
      font-size: 15px;
      color: #333333;
    }
  }

  .number {
    position: absolute;
    display: block;
    width: auto; 
    height: 60px;
    line-height: 50px;
    padding-left: 20px; padding-right: 20px; 
    font-family: Oswald;
    font-weight: 500;
    font-size: 24px;
    border: 3px solid #1a3866; 
    border-radius: 6px;
    background-color: #1a3866;
    color: #fff;
  }

  .number {
      top: -28px;
      left: -24px;  
  }
}

.news_wrapper__inner {
  position: relative; 
  padding-bottom: 64px;
}

.form-prize_input { 
  display: block;

  input { margin-right: 6px; }
}

.form-prize_wrapper { 
  margin-top: 30px;
  text-align: center;
}

.btn_red {
  padding: 20px 60px;
  font-family: Oswald;
  font-size: 21px;
  text-align: center;
  border-radius: 3px;
  background: #eb1c24;
  color: #fff;
  border: none;
}

.form_wrapper {
  padding-bottom: 48px;
  color: #fff;
  background-color: #205469;

  a { 
    text-decoration: underline;
    color: #fff;
  }

  h3 { 
    padding: 20px 0px 10px 0px;
    font-family: Oswald;
    font-weight: 500;
    font-size: 18px;
  }
}

.required { color: red; }

.timeline-wrapper {
  background-color: #c7d3ea;

  @media screen and (max-width: 767px) { padding: 0px 0px 36px 0px;  }
  @media screen and (min-width: 768px) { 
    min-height: 990px;
    padding: 0px 38px 99px 38px;  
  }

  @media screen and (min-width: 1200px) { min-height: 700px; }

  .process__title { text-align: center; }

  .process {
    max-width: 1170px;

    .process__row {
      position: relative;

      
      @media screen and (max-width: 767px) {
        height: auto; 
        padding-bottom: 26px;
        background-repeat: repeat-y;
        background-position: 50px 54px; 
      }

      @media screen and (min-width: 768px) { 
        min-height: 154px;  
        background-repeat: no-repeat;
        background-position: center;  
      }

      @media screen and (min-width: 1200px) { min-height: 158px; }

      .content {
        top: 0; 
        background-color: #fff; 
        color: #434647;

        @media screen and (max-width: 479px) { position: relative; width: 58%; left: 108px; padding: 13px;   }
        @media screen and (min-width: 480px) { position: relative; width: 70%; left: 108px; padding: 26px; }
        @media screen and (min-width: 768px) { position: absolute; width: 43%; left: 0; }

        .content_inner {
          @media screen and (max-width: 767px) { display: inline-block; }
          @media screen and (min-width: 480px) { display: flex; }
          @media screen and (min-width: 768px) { display: flex; }
        }

        h3 {
          margin: 0px;
          padding-bottom: 10px;
          font-size: 18px;
          line-height: 24px;
        }

        p { margin: 0px; }

        ul {
          @media screen and (max-width: 767px) { padding-left: 13px;  }
          @media screen and (min-width: 480px) { padding-left: 26px; }

          li { padding-bottom: 26px; }
        }

        .content__text { 
          font-family: Arial;
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;

          @media screen and (max-width: 479px) { width: 100%; }
          @media screen and (min-width: 480px) { 
            width: 82%; 
            padding-right: 8px; 
          }
        }

        .content__image { 
          @media screen and (max-width: 479px) { display: none; }
          @media screen and (min-width: 480px) { width: 28%; }
          
          img { 
            max-width: 100%;
            float: right; 
          }
        }
      }

      .content__title { 
        width: 100%;
        font-family: Oswald;
        font-weight: 500;
        font-size: 20px;
        color: #203868;
      }

      .content-right:after, .content-right:before {

        @media screen and (max-width: 767px) { 
          left: -24px;
          top: 30px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        @media screen and (min-width: 768px) { 
          left: 100%;
          top: 30px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
      }

      .content-left:after, .content-left:before {
          left: -24px;
          top: 30px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        
      }

      .content-right:after {
        @media screen and (max-width: 767px) { 
          border-color: rgba(136, 183, 213, 0);
          border-right-color: #fff;
          border-width: 12px;
          margin-top: -12px;
        }

        @media screen and (min-width: 768px) { 
          border-color: rgba(136, 183, 213, 0);
          border-left-color: #fff;
          border-width: 12px;
          margin-top: -12px;
        }
      }

      .content-left:after {
        border-color: rgba(136, 183, 213, 0);
        border-right-color: #fff;
        border-width: 12px;
        margin-top: -12px;
      }

      .number {
        position: absolute;
        width: 80px; 
        height: 80px; 
        border-radius: 100%; 
        padding-top: 14px;
        font-family: Oswald;
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
        background-color: #e8f0f3;

        @media screen and (max-width: 479px) { display: inline-block; }
        @media screen and (max-width: 767px) { top: 0px; left: 26px; }
        @media screen and (min-width: 768px) { left: 46%; }
        @media screen and (min-width: 992px) { left: 47%; }
        @media screen and (min-width: 1200px) { left: 46.0%; }
      }

      .first-last { 
        color: #fff;
        border: 4px solid #203868; 
        background-color: #203868;
      }

      .second-last { 
        color: #fff;
        border: 4px solid #203868;
        background-color: #203868;

      }
    }

    .process__row:last-child { background-image: none;  }
  }

  .process .process__row:not(:first-child) { 
    @media screen and (min-width: 768px) { margin-top: -50px; }
  }

  .process .process__row:nth-child(2n) .content {
    @media screen and (min-width: 768px) { 
      left: auto; 
      right: 0; 
    }
  } 
}

.image-header { 
  width: 100%; 
  background-image: url('https://neferoveseznamky.cz/wp-content/themes/neferoveseznamky/img/image-header_03.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 479px) { 
    min-height: 380px; 
    background-position: 60% 0;  
  }

  @media screen and (min-width: 480px) { 
    min-height: 420px; 
    background-position: 60% 0; 
  }

  @media screen and (min-width: 768px) { 
    min-height: 460px; 
    background-position: 60% 0; 
  }

  @media screen and (min-width: 992px) { 
    min-height: 500px; 
    background-position: 50% 0;
  }

  @media screen and (min-width: 1200px) { 
    min-height: 580px; 
    background-position: 40% 0;
  }

  @media screen and (min-width: 1920px) { 
    min-height: 660px; 
    background-position: 20% 0;
  }

  @media screen and (min-width: 2560px) { 
    min-height: 660px; 
  }
}

.page { 
  max-width: 760px;
  padding-top: 30px;

  ol.cisla {
    counter-reset: list;
    margin: 0;
  }

  ol.cisla > li {
    list-style: none;
    position: relative;
  }

  ol.cisla > li:before {
    counter-increment: list;
    content: counter(list) ") ";
    position: absolute;
    left: -1.4em;

    font-family: Oswald;
    font-weight: 500;
    font-size: 20px;
    color: #333;
  }

   table, th, td{
  border: 1px solid black;
  border-collapse: collapse;
 }

 th {
  font-weight: bold;
 }
ol.pismena {
    counter-reset: list;
    margin: 0;
}

ol.pismena > li {
    list-style: none;
    position: relative;
}

ol.pismena > li:before {
    counter-increment: list;
    content: counter(list, lower-alpha) ") ";
    position: absolute;
    left: -1.4em;
}


ol.cisla > li:before {
    counter-increment: list;
    content: counter(list) ") ";
    position: absolute;
    left: -1.4em;
    top: -.2em;
}

td,th {
  padding:.75em;
}

@media only screen and (max-width: 500px){
  table { 
    font-size: .85em; 
    margin-left: -40px;
  }
  td,th {
    padding:1px;
  } 
}

li h3 {
    font-family: Oswald;
    font-weight: 500;
    font-size: 20px;
    color: #333;
  } 

}

.faq_wrapper { 
  max-width: 760px;
  margin-top: 3em;

  .item { padding-bottom: 10px; }

  .item-header {
    font-family: Oswald;
    font-size: 20px;
    line-height:28px;
    padding-top: .5em;
  }
  
  .item-header .collapsed:before {
    content: "\f067";
    font-family: "FontAwesome";
  }

  .item-link { color: #203868; }

  .item-link:focus, .item-link:hover { color: #203868; text-decoration: none; }

  .item-header :before {
    content: "\f068";
    font-family: "FontAwesome";
  }
  
  .item-body {
    padding-top: .5em; 
    line-height: 24px;
    font-family: Arial;
    font-weight: normal;
    font-size: 15px;
  }
}